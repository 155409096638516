<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

const loginGuard = useLoginGuard();
const localPath = useLocalePath();
const { open } = useFunrizeModals();
const { t } = useT();
const { promotions, refresh } = useHomePage();
refresh();

const sliderRef = ref();
const isDisabledNextMove = ref(false);

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto", spacing: 0 },
	animationEnded(slider) {
		isDisabledNextMove.value = (slider.track.details.slides.at(-1)?.portion ?? 0) >= 0.95;
	},
	created: () => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
};

const handleNavigateToPromotions = () => {
	loginGuard({
		success: () => {
			navigateTo(localPath("/promotions/"));
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

watch(
	() => promotions.value.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<div class="promotions">
		<AText class="title text-cannes" data-tid="promotions" variant="tampa" as="h3" :modifiers="['bold']">
			<NuxtIcon filled name="20/bonus" />
			<span>{{ t("Promotions") }}</span>
		</AText>

		<ASlider ref="sliderRef" :options="sliderOptions">
			<OPromotions :promotions="promotions" view="slider" />

			<template #dots="{ slider, activeIndex }">
				<MSliderNavigation
					location="home-promo"
					location-tid="promotions"
					:is-disabled-prev="activeIndex === 0"
					:is-disabled-next="isDisabledNextMove"
					:view-all-label="t('view all')"
					@click-prev="slider?.prev()"
					@click-next="slider?.next()"
					@click-view-all="handleNavigateToPromotions"
				/>
			</template>
		</ASlider>
	</div>
</template>
<style lang="scss" scoped>
.promotions {
	padding: gutter(3) 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
	}

	.title {
		display: flex;
		align-items: center;
		gap: gutter(1);
	}

	&:deep(.keen-slider) {
		display: flex;
		position: relative;
		padding-top: gutter(3);

		@include media-breakpoint-down(md) {
			padding-top: gutter(2.5);
		}

		.keen-slider__slide {
			width: calc(328px + 20px);
			padding-right: gutter(2.5);
		}
	}

	.navigation-holder {
		position: absolute;
		right: 0;
		top: 24px;

		@include media-breakpoint-down(md) {
			top: 20px;
		}
	}
}
</style>
